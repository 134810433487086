import React, { useEffect, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import Lists from '../../../Components/Lists/Lists';
import { fadeIn } from '../../../Functions/GlobalAnimations';

import { PropTypes } from "prop-types";
import { brands } from '../data';

const ProductDetail = (props) => {

  const { company: slug, id } = useParams()

  const [selectedBrand, setSelectedBrand] = useState([])
  const [product, setProduct] = useState({})
  const [description, setDescription] = useState([])

  useEffect(() => {
    const brand = brands.filter((item) => item.slug === slug)[0]
    const product = brand.products.filter((item) => item.id === id)[0]
    setSelectedBrand(() => brand)
    setProduct(product)
    if (!product.description) return
    setDescription(
      () => typeof product.description === "string" ? product.description : product.description.map((item) => { return { content: item } }));
  }, [slug, id])

  return (
    <div className="single-product" style={props.style}>
      <section className="bg-darkgray py-[20px]">
        <Container>
          <Row className="items-center justify-between mb-0">
            <Col xl={8} lg={6} className="breadcrumb justify-start  font-serif md:mt-[10px] mb-0 md:justify-center">
              <ul className="xs-text-center">
                <li><Link aria-label="breadcrumb" to="/" className="hover:text-white">Home</Link></li>
                <li><Link aria-label="breadcrumb" to={`/${selectedBrand.slug}/products`} className="hover:text-white">{selectedBrand.title}</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="h-full relative">
          <Row className="row items-stretch justify-center h-[200px] md:h-[275px] sm:h-[250px]">
            <Col xl={8} lg={8} md={8} xs={12} className="text-center flex justify-center flex-col">
              <h1 className="text-darkgray mb-[0px] font-serif font-medium -tracking-[1px] xs:mb-[0px] fs-1">{product.title}</h1>
            </Col>
          </Row>
          <Row className="items-center justify-center">
            <Col lg={6} xl={{ offset: 1 }}>
              <img loading="lazy" width="400" height="400" src={`/assets/${product.image}`} alt={product.title} />
            </Col>
            {product.description && <Col xl={5} lg={6} className="md:mb-[50px]">
              {
                typeof product.description === "string"
                  ? <p className="mb-[25px]">{description}</p>
                  : <Lists theme="list-style-04" data={description} animation={fadeIn} />
              }
            </Col>}

          </Row>
          {product.specifications && product.specifications.length > 0 &&
            <Row className="items-center">
              <Col>
                <table className="w-full">
                  <tbody className="xs:flex xs:flex-col xs:w-[100%]">

                    {product.specifications.map((item, i) => {
                      return (
                        <tr key={i} className={`${i % 2 === 0 ? "bg-lightgray" : ""} border-b border-[#e8e8e8] py-[10px] px-[15px] xs:flex xs:flex-col xs:w-[100%]`}>
                          <th className="text-darkgray font-medium w-[250px] px-[15px]">{item.title}</th>

                          {typeof item.value === "object"
                            ? <Lists theme="list-style-06" className="" data={item.value.map((item) => { return { content: item } })} animation={fadeIn} />
                            : <td className="py-[10px] px-[15px]">{item.value}</td>}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          }
        </Container>
      </section>
    </div>
  )
}

ProductDetail.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string | PropTypes.array,
  description: PropTypes.string,
  specifications: PropTypes.array
}

export default ProductDetail