export const brands = [
  {
    image: "/assets/img/fgv-valvole.png",
    link: "/produtos",
    title: "Special Valves",
    slug: "fgv",
    description:
      `FGV specialises in designing and manufacturing a wide range of customised high performance valves either manually operated or actuated and for any kind of fluid used in the Oil, Gas, Water, Steam, Petrochemical and Power Generating Industry. Within their own facility, they are currently capable of designing, assembling and testing in accordance with almost all the recognised standards and Customer specifications. FG Valvole range of production includes the use of such unique materials as Super Duplex, Duplex, 6Mo, Monel, Incoloy 825, Inconel and many other alloys. Main production includes Ball, Axial Flow, Gate, Globe and Check Valves in different executions, materials and ratings.`,
    products: [
      {
        "id": "trunnion-side-entry-ball-valve-1",
        "title": "Trunnion Side Entry Ball Valve",
        "category": "Ball",
        "image": "img/products/fgv/trunnion-side-entry-ball-valve.png",
        "subtitle": "Ball",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/trunnion-side-entry-ball-valve/",
        "slug": "trunnion-side-entry-ball-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 60”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Two or three pieces body construction; bolted joints",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings : Tungsten Carbide / Chromium Carbide / ENP",
              "Soft or Metal Seated",
              "Single or Double Piston Effect Sea",
              "Double block and bleed",
              "Overlay on Sealing Surfaces Only",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Clad",
              "Specific design for HIPPS, ESD application",
              "Special constructions available for High and Low Temperature (Cryogenic), erosive /abrasive, corrosive service"
            ]
          }
        ]
      },
      {
        "id": "floating-side-entry-ball-valve-2",
        "title": "Floating Side Entry Ball Valve",
        "category": "Ball",
        "image": "img/products/fgv/floating-side-entry-ball-valve.png",
        "subtitle": "Ball",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/floating-side-entry-ball-valve/",
        "slug": "floating-side-entry-ball-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 2500"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 10”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Two or three pieces body construction; bolted joints",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Overlays: Alloy 625 / 316L",
              "Coatings : Tungsten Carbide / Chromium Carbide / ENP",
              "Soft or Metal Seated",
              "Metal Seated construction to suit very severe service requirements. Special constructions available for extremely High and Low Temperature (Cryogenic), erosive /abrasive, corrosive service",
              "Bidirectional design available",
              "Overlay on dynamic sealing area",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Clad",
              "Specific design for HIPPS, ESD application"
            ]
          }
        ]
      },
      {
        "id": "top-entry-ball-valve-3",
        "title": "Top Entry Ball Valve",
        "category": "Ball",
        "image": "img/products/fgv/top-entry-ball-valve.png",
        "subtitle": "Ball",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/top-entry-ball-valve/",
        "slug": "top-entry-ball-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 48”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Trunnion or Floating contruction",
              "Extremely wide selection of materials (forging or casting) for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings : Tungsten Carbide / Chromium Carbide / ENP",
              "Soft or Metal Seated",
              "Single or Double Piston Effect Sea",
              "Double block and bleed",
              "Overlay on Sealing Surfaces Only",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Clad"
            ]
          }
        ]
      },
      {
        "id": "fully-welded-body-ball-valve-4",
        "title": "Fully Welded Body Ball Valve",
        "category": "Ball",
        "image": "img/products/fgv/fully-welded-body-ball-valve.png",
        "subtitle": "Ball",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/fully-welded-body-ball-valve/",
        "slug": "fully-welded-body-ball-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 60”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Two or three pieces body sections butt welded together; no leak path to environment",
              "Specific design for underground service with operable stem extension and bypass system",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings : Tungsten Carbide / Chromium Carbide / ENP",
              "Soft or Metal Seated",
              "Single or Double Piston Effect Sea",
              "Double block and bleed",
              "Overlay on Sealing Surfaces Only",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Clad"
            ]
          }
        ]
      },
      {
        "id": "silent-segment-ball-valve-5",
        "title": "Silent Segment Ball Valve",
        "category": "Ball",
        "image": "img/products/fgv/silent-segment-ball-valve.png",
        "subtitle": "Ball",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/silent-segment-ball-valve/",
        "slug": "silent-segment-ball-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 600"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "1” – 32”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "One, Two or Three pieces body construction",
              "Anti-blowout shaft",
              "Special trim available with attenuator for anti-cavitation and noise reduction",
              "Low emissions according to ISO 15848 with wide temperature range and the longest endurance class",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings: Tungsten Carbide/Chromium Carbide/ENP",
              "Soft or Metal Seated"
            ]
          }
        ]
      },
      {
        "id": "axial-flow-valves-1",
        "title": "Axial flow valves",
        "category": "Axial Flow",
        "image": "img/products/fgv/axial-flow-valves.png",
        "subtitle": "Axial Flow",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/axial-flow-valves/",
        "slug": "axial-flow-valves",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 2500",
              "API 2000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "2” - 48”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Valves for tight isolation service (ON-OFF)",
              "Valves for throttling service (FLOW CONTROL)",
              "Reduced valves stroke for quick closing time (SDV-HIPPS)",
              "Extended design life and low maintenance interventions",
              "Balanced-pressure disc design",
              "Extremely wide selection of materials for both body and internals including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 cr, Ni-alloys and titanium.",
              "Production /Treatment / Transmission and Transportation /Fire Pump",
              "Storage and distribution",
              "Anti surge / throttling service",
              "Choke and by-pass service",
              "Tank loading / venting and depressurization",
              "PLUG TYPE TRIM DESIGN for large flow rate and high pressure drop",
              "(SINGLE) CAGE TRIM DESIGN for gas, liquid and mixed phases service where high pressure drop is predicted and noise level shall be limited",
              "(MULTI) CAGE TRIM DESIGN for high pressure drops and large flow rate. Multi cage trim can be sized to suit any specific flow condition with different characteristics"
            ]
          }
        ]
      },
      {
        "id": "pressure-seal-gate-valve-1",
        "title": "Pressure Seal Gate Valve",
        "category": "Gate",
        "image": "img/products/fgv/pressure-seal-gate-valve.png",
        "subtitle": "Gate",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/pressure-seal-gate-valve/",
        "slug": "pressure-seal-gate-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 300 – 4500"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "Sizes 1.1/2” – 36” Pressure Classes ANSI 300 – 4500"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Pressure Seal Bonnet, Outside Screwed &amp; Yoke",
              "Wedge Type:1 piece Flexible Wedge for 2” to 36” size valves in all classes\n<ul>\n<li>1 piece Solid wedge for 1-1/2”to 12” size valves in all classes of Light wall Thickness type</li>\n<li>Double Disc Parallel Seats for 2” to 60” size valves in all classes</li>\n</ul>\n",
              "Design Range:\n<ul>\n<li>Heavy wall Thickness Type (API 600, ASME B16.34)</li>\n<li>Light wall Thickness Type (ASME B16.34, API603)</li>\n</ul>\n"
            ]
          }
        ]
      },
      {
        "id": "wedge-gate-bolted-bonnet-pressure-seal-valve-2",
        "title": "Wedge Gate Bolted Bonnet Valve",
        "category": "Gate",
        "image": "img/products/fgv/wedge-gate-bolted-bonnet-pressure-seal-valve.png",
        "subtitle": "Gate",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/wedge-gate-bolted-bonnet-pressure-seal-valve/",
        "slug": "wedge-gate-bolted-bonnet-pressure-seal-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "1.1/2” – 60”"
            ]
          },
          {
            "title": "Main Features"
          }
        ]
      },
      {
        "id": "through-conduit-slab-gate-valve-3",
        "title": "Through Conduit Slab Gate Valve",
        "category": "Gate",
        "image": "img/products/fgv/through-conduit-slab-gate-valve.png",
        "subtitle": "Gate",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/through-conduit-slab-gate-valve/",
        "slug": "through-conduit-slab-gate-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 4500",
              "API 2000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "1.1/2” - 56”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Top Entry construction, Through conduit suitable for pigging, Floating seats, Anti blowout stem, Cast or forged, Fire safe certified, Double block &amp; bleed, By-directional, Self-relieving design, Rising-Stem and Non-Rising-Stem design available, Low actuation force demand",
              "Disc Type: 1 piece Solid Disc for 1-1/2” to 56” size valves in all classes",
              "Design Range:<br>– API 6D, API 6A, ISO 10423<br>– Wall Thickness according ASME B16.34<br>– End to end dimension according to ASME B16.10"
            ]
          }
        ]
      },
      {
        "id": "through-conduit-double-expanding-gate-valve-4",
        "title": "Through Conduit Double Expanding Gate Valve",
        "category": "Gate",
        "image": "img/products/fgv/through-conduit-double-expanding-gate-valve.png",
        "subtitle": "Gate",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/through-conduit-double-expanding-gate-valve/",
        "slug": "through-conduit-double-expanding-gate-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "2” – 36”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Top Entry construction, Through conduit suitable for pigging, Anti blowout stem, Cast or forged, Fire safe certified, Double block &amp; bleed, By-directional, Rising-Stem and Non-Rising-Stem design available, Low actuation force demand",
              "Disc Type: Double Disc Parallel Seats for 2” to 36” size valves in all classes",
              "Design Range:<br>– API 6D, API 6A, ISO 10423<br>– Wall Thickness according ASME B16.34<br>– End to end dimension according o ASME B16.10"
            ]
          }
        ]
      },
      {
        "id": "nozzle-check-valve-1",
        "title": "Nozzle Check Valve",
        "category": "Check",
        "image": "img/products/fgv/nozzle-check-valve.png",
        "subtitle": "Check",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/nozzle-check-valve/",
        "slug": "nozzle-check-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 60”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type:<br>– Short pattern (std FGV) and long pattern (ANSI B16.10) design available<br>– Metal Seated<br>– Soft seated construction available for bubble-tight shutoff<br>– Valve conduit contour designed to provide maximum fluid-dynamic efficiency<br>– Different spring selection is available to suit the required range of flow rates, combining minimum pressure drop and no-chattering",
              "Disc Type: Non Slam Disc type for 1/2” to 60” size valves in all classes",
              "Design Range: Heavy wall Thickness Type (ISO 14313, API 594, API 6D, API 6A)"
            ]
          }
        ]
      },
      {
        "id": "swing-check-valve-2",
        "title": "Swing Check Valve",
        "category": "Check",
        "image": "img/products/fgv/swing-check-valve.png",
        "subtitle": "Check",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/swing-check-valve/",
        "slug": "swing-check-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 4500",
              "API 2000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” - 60”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Bolted Bonnet or Pressure Seal available",
              "Metal Seated",
              "Disc Type: Clapper Disc for ½” 60”",
              "Design Range:<br>– Heavy wall Thickness Type (ISO 14313, API 6D, API 6A, BS1868)<br>– Light wall Thickness Type (ASME B16.34, API 603)",
              "Material Selection: Carbon Steel, Low Alloy Steel, Stainless Steel, Duplex 22/25 Cr, Ni-Alloys and Titanium"
            ]
          }
        ]
      },
      {
        "id": "dual-platetilting-disc-check-valve-3",
        "title": "Dual Plate/Tilting Disc Check Valve",
        "category": "Check",
        "image": "img/products/fgv/dual-platetilting-disc-check-valve.png",
        "subtitle": "Check",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/dual-platetilting-disc-check-valve/",
        "slug": "dual-platetilting-disc-check-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 4500",
              "API 2000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” - 60”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type:\n<ul>\n<li><strong>DUAL</strong>\n<ul>\n<li>Wafer and Monobloc body (Flanged/Hub Ends)</li>\n<li>Hing pins are retained into the body</li>\n<li>Lug type design option</li>\n<li>Disc Type: Dual Plates type for ½” to 60”</li>\n</ul>\n</li>\n<li><strong>TILTING</strong>\n<ul>\n<li>Bolted Bonnet or Pressure Seal available</li>\n<li>Short Pattern can be supplied Wafer Type or Monobloc (Flanged)</li>\n<li>Disc Type: Tilting Non Slam Disc type for ½” to 60”</li>\n<li>Soft seated construction for bubble-tight shutoff</li>\n</ul>\n</li>\n</ul>\n",
              "Material Selection: Carbon Steel, Low Alloy Steel, Stainless Steel, Duplex 22/25 Cr, Ni-Alloys and Titanium",
              "Hardfacings: Stellite, Colmoloy, Tungsten and Chromium Carbides"
            ]
          }
        ]
      },
      {
        "id": "pressure-seal-globe-valve-1",
        "title": "Pressure Seal Globe Valve",
        "category": "Globe",
        "image": "img/products/fgv/pressure-seal-globe-valve.png",
        "subtitle": "Globe",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/pressure-seal-globe-valve/",
        "slug": "pressure-seal-globe-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 300 - 4500"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” - 36”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Pressure Seal Bonnet,",
              "Outside Screwed &amp; Yoke",
              "Disc Type: Plug Disc type for 1/2” to 36” size valves in all classes",
              "Design Range: Heavy wall Thickness Type (BS1873, ASME B16.34), Light wall",
              "Thickness Type (ASME B16.34, API603)",
              "Material selection: WCB/LCB C5/WC1/WC6/WC9/C12 C12A/CF8/CF8M/CF8C"
            ]
          }
        ]
      },
      {
        "id": "globe-bolted-bonnet-pressure-valve-2",
        "title": "Globe Bolted Bonnet Valve",
        "category": "Globe",
        "image": "img/products/fgv/globe-bolted-bonnet-pressure-valve.png",
        "subtitle": "Globe",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/globe-bolted-bonnet-pressure-valve/",
        "slug": "globe-bolted-bonnet-pressure-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 4500",
              "API 2000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” - 36”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Production Type: Bolted Bonnet, Outside Screwed &amp; Yoke, Straight Pattern, Y Pattern, Angle",
              "Disc Type: Plug Disc type for 1/2” to 36” size valves in all classes, Parabolic Disc type for 1/2” to 36” size",
              "Design Range: Heavy wall Thickness Type (ISO 14313, BS1873, API 6A), Light wall Thickness Type (ASME B16.34, API603)",
              "Applicable Materials: Carbon Steel,<br>Alloy Steel, Stainless Steel, Special SS,<br>Special Alloy"
            ]
          }
        ]
      },
      {
        "id": "modular-double-block-and-bleed-valve-1",
        "title": "Modular Double Block and Bleed Valve",
        "category": "Modular Double Block and Bleed",
        "image": "img/products/fgv/modular-double-block-and-bleed-valve.png",
        "subtitle": "Modular Double Block and Bleed",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/modular-double-block-and-bleed-valve/",
        "slug": "modular-double-block-and-bleed-valve",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 – 4500",
              "API 2000 – 15000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "½” – 24”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Available in floating and trunnion construction",
              "Split body construction; bolted joints; cartridge type",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings : Tungsten Carbide / Chromium Carbide / ENP",
              "Soft or Metal Seated",
              "Single or Double Piston Effect Sea",
              "Double block and bleed",
              "Overlay on Sealing Surfaces Only",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Clad",
              "Special constructions available for High and Low Temperature (Cryogenic), erosive /abrasive, corrosive service",
              "Ball/Ball",
              "Ball/Globe",
              "Ball/Needle/Ball",
              "Ball/Needle/Ball Cartridge Type",
              "Ball/Needle/Globe",
              "Ball/Needle/Needle/Ball"
            ]
          }
        ]
      },
      {
        "id": "pig-valves-1",
        "title": "Pig Valves",
        "category": "Pig",
        "image": "img/products/fgv/pig-valves.png",
        "subtitle": "Pig",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/pig-valves/",
        "slug": "pig-valves",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 2500"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "2” - 24”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Two or three pieces body construction",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings: Tungsten Carbide/Chromium Carbide/ENP",
              "Soft or Metal Seated",
              "Metal Seated construction to suit very severe service requirements",
              "Special constructions available for extremely High and Low Temperature (Cryogenic), erosive/abrasive, corrosive service",
              "Bidirectional design available",
              "Designed for loading and receiving",
              "Compact design and customized inner diameter",
              "Overlay on dynamic sealing area",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Cladded"
            ]
          }
        ]
      },
      {
        "id": "multiport-valves-1",
        "title": "Multiport Valves",
        "category": "Multiport",
        "image": "img/products/fgv/multiport-valves.png",
        "subtitle": "Multiport",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/multiport-valves/",
        "slug": "multiport-valves",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 150 - 2500"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "¾” - 14”"
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Top Entry, Two or three pieces body construction",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings: Tungsten Carbide/Chromium Carbide/ENP",
              "Soft or Metal Seated",
              "Metal Seated construction to suit very severe service requirements",
              "Special constructions available for extremely High and Low Temperature (Cryogenic), erosive/abrasive, corrosive service",
              "Overlay on dynamic sealing area",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Cladded"
            ]
          }
        ]
      },
      {
        "id": "choke-valves-1",
        "title": "Choke Valves",
        "category": "Choke",
        "image": "img/products/fgv/choke-valves.png",
        "subtitle": "Choke",
        "btnTitle": "View details",
        "link": "https://www.fgvalvole.com/project/choke-valves/",
        "slug": "choke-valves",
        "specifications": [
          {
            "title": "Pressure Classes",
            "value": [
              "ANSI 300 - 2500",
              "API 3000 - 20000"
            ]
          },
          {
            "title": "Sizes",
            "value": [
              "1” - 24” 1.13/16\" - 11\""
            ]
          },
          {
            "title": "Main Features",
            "value": [
              "Top Entry or Two pieces body construction",
              "Quick Opening, Linear or Equal Percentage Flow characteristics",
              "Adjustable and Positive design",
              "Needle, Bean, Cage, Multi Cage trim type",
              "External Sleeve",
              "Pressure Balanced seals",
              "Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium",
              "Overlays: Alloy 625 / 316L",
              "Coatings: Tungsten Carbide/Chromium Carbide/ENP",
              "Solid sintered Tungsten Carbide/Chromium Carbide (Plug, Bean, Cage)",
              "Metal Seal and Seated construction to suit very severe service requirements",
              "Special constructions available for extremely High and Low Temperature (Cryogenic), erosive/abrasive, corrosive service",
              "Overlay on dynamic sealing area",
              "Cladding in Seat Pocket Areas",
              "Fully Internally Cladded"
            ]
          }
        ]
      },
      // {
      //   "id": "butterfly-valves-1",
      //   "title": "Butterfly Valves",
      //   "category": "Butterfly",
      //   "image": "img/products/fgv/butterfly-valves.png",
      //   "subtitle": "Butterfly",
      //   "btnTitle": "View details",
      //   "link": "https://www.fgvalvole.com/project/butterfly-valves/",
      //   "slug": "butterfly-valves",
      //   "specifications": [
      //     {
      //       "title": "Pressure Classes",
      //       "value": [
      //         "ANSI 150 – 900",
      //         "API 2000 – 20000"
      //       ]
      //     },
      //     {
      //       "title": "Sizes",
      //       "value": [
      //         "2” – 60”"
      //       ]
      //     },
      //     {
      //       "title": "Main Features",
      //       "value": [
      //         "<span style=\"font-weight: 400;\">Triple Offset/Double Eccentric/Concentric Execution</span>",
      //         "<span style=\"font-weight: 400;\">Extremely wide selection of materials for both body and internals, including: carbon steel, low alloy steels, stainless steels, Duplex 22/25 Cr, Ni-alloys and titanium</span>",
      //         "<span style=\"font-weight: 400;\">Soft or Metal Seated</span>",
      //         "<span style=\"font-weight: 400;\">Special constructions available for High and Low Temperature (Cryogenic), erosive /abrasive, corrosive service/Water Trasmission</span>"
      //       ]
      //     }
      //   ]
      // }
    ],
  },
  {
    image: "/assets/img/metroval.png",
    bgColor: "#fff",
    borderColor: "#262b35",
    title: "Metrology",
    slug: "metroval",
    description:
      "The Metroval history began in 1987 when the company Tecnobrás S.A., water meters manufacturer, won a contract for technology transfer, for manufacturing positive displacement meters, with Bopp & Reuther Messtechnik GmbH, from Germany, known worldwide for its long tradition in the instrumentation and control field. This contract was transferred to Metroval in 1988, date of the company founding. In 1991, another technology transfer contract, with the company Rheonik Messgerate GmbH - also from Germany, granted the mass meters manufacturing by Coriolis effect, in a pioneering move in Brazil. With a prominent position in the flow measurement domesc market, the years followed with numerous accomplishments: One of the first domesc companies to obtain in 1994 an ISO 9000 certification; Inmetro Accreditation in 2004, of its flow gage calibration laboratories, integrang RBC Brazilian Calibration Laboratories Network; Conquest of the largest flow measurement contract of national history: the adequacy of four Petrobras assets in the Campos Basin, totaling 14 oil platforms; Opening of a branch office in Macaé (RJ), in order to provide services and support to Oil and Gas companies. With almost 3 decades of experience, today Metroval is proud to count more than 30,000 meters sold to over 6,000 customers. A genuinely Brazilian company and national leader in measurement technology, Its commitment to quality and technology has placed it as the only company in Lan America to completely dominate the production cycle of flow measurement systems, enabling the continued development of fluid control solutions.",
    products: [
      {
        id: "metroval-1",
        title: "Orifice Fitting Dual Chamber",
        image: "img/products/metroval/orifice-fitting-dual-chamber.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Metroval offers different types of orifice carriers, including the Single Chamber, the Double Chamber that does not require interruption of the process for the replacement of the plates, in addition to the double block and bleed option that offers extra safety during this operation, meeting international standards, AGA, API and ISO. The manufacture of Metroval orifice fittings is 100% national, fulfilling the requirements of international standards for the product, as: ISO 5167, AGA 3, ASME B16.36, ASME B16.5. Materials according to ASTM, with certificate. The usual materials are:Body: Carbon steel (A216 WCB, A216 WCC, A352 LCC), Stainless Steel (A351 CF8M, A351 CF3M), Duplex (A995 4A, 5A, 6A) Internal components: stainless steel 316. When specified fulfilling the specifications of NACE recommendations. Special materials available under request. The Double Block orifice fitting was developed for maximum operational safety, applicable where there is need for redundancy in the sealing for the orifice plate removal without the depressurizing the pipeline. The Metroval DBB is modular, that is, the module (Intermediate chamber) can be installed in standard double chamber Metroval orifice fitting.",
      },
      {
        id: "metroval-2",
        title: "Metrocone high quality liquid and gas flow measurement",
        image: "img/products/metroval/metrocone.png",
        subtitle: "Cone Meters",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          `FLUIDS 
          Oil and its derivates;
          Water/oil emulsions;
          Fresh and injection water;
          Dry and humid natural gas;
          Vapor;
          Others upon request. 
          APPLICATIONS 
          Gas and water injection into wells; 
          Burners and furnaces;
          Boilers;
          Compressors and generators; 
          Ideal for tax measurement, gas custody appropriation and transfer 
          `,
      },
      {
        id: "metroval-3",
        title: "AUTOMATIC SAMPLING SYSTEM",
        image: "img/products/metroval/automatic-sampling-system.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The Metroval sampling systems are designed for custody transfer, fiscal, allocation, well test, test separator and other purposes that require representative samples of liquids for composition and quality measurement.They can be applied on offshore platforms, FPSOs including offloading lines, terminals and pipelines in accordance with the sampling standards ISO 3171, ASTM D 4177, API 8.2 and IP 6.2. The sampler probes are supplied with a performance warranty to work under extreme process conditions and guarantees long Mean Time Between Failure (MTBF). These characteristics are guaranteed due to the use of very high resistance sealing materials (PCTFE and PEEK). In general, the Metroval automatic sampler probes work well under conditions that no other probes can perform.",
      },
      {
        id: "metroval-4",
        title: "Chemicals Products Injection System",
        image: "img/products/metroval/chemical-injection.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          `Metroval's chemical injection system is based on effective flow control and measurement of the quantity of injected liquids. Its biggest difference is automatic flow control through a valve/flow meter set. This combination allows absolute control of the dosing process.
          Automatic and accurate chemicals control for oil production 
          Metroval dosing and additive systems are the ideal solution for dosing chemical additives or chemical compounds that require accurate dosing of different liquid components. 
          In this Metroval systems, each compound is individually measured by a high accuracy flow meter and its dosage is controlled by a control valve, in order to guarantee the correct dosing established by the customer's specification. 
          The system can operate with an existing supervisory, or it can be provided with an interface that allows the programming and monitoring of variables directly by the user through a human-machine interface (HMI). 
          These systems can operate continuously or in batches, designed according to the customer's specifications to meet the most varied process characteristics, such as flow, pressure, temperature and chemical compatibility. 
          Benefits 
          Measured and controlled fluids individually – greater accuracy 
          Modular construction, rack mounting 
          Usable in classified area 
          High pressures (up to 15,000 PSI) and low flow rates (up to 0.5 l/h) 
          Applications 
          Dosing, Additivation and Mixing of multiple products 
          Process with continuous operation or per batch
          Chemical and mercaptan injection
          `,
      },
      {
        id: "metroval-5",
        title: "Metroval Fuel Consumption Monitor - MCCM",
        image: "img/products/metroval/fuel-consumption-monitor-mccm.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "The MCCM is a volumetric fuel measurement system basically composed of an Electronic Unit that manages, processes and transmits data from two or more Flow Meters associated with it. It was developed with the aim of providing fuel managers, in various industrial segments, with all the data they need so that they can obtain maximum energy performance from their equipment powered by diesel engines.",
      },
      // {
      //   id: "metroval-6",
      //   title: "Measurement Systems Embedded in TRR Tank Trucks",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "The product's operating principle is to measure and control the volume delivered for each shipment, always aiming for agility, ease and precise and efficient control of these supplies. The robust tanker truck supply system sold by Metroval has a meter with Inmetro model approval for custody transfer. Furthermore, the system has a robust yet compact structure, simple operation and high precision, with a maximum error of 0.2% in the measured range. The product also has a supply nozzle detection system, control and online GPRS mapping.",
      // },
      // {
      //   id: "metroval-7",
      //   title: "Fluid Supply Systems for Automotive Assembly Lines",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "Customized by Metroval, these supply systems use the same principle as oval gear-type positive displacement volumetric meters. The equipment has high measurement performance, with a maximum error of 0.2% of the measured value in the range in which it is calibrated, which guarantees supplies with repetitive volumes and the lowest possible error rate. The robust and easy-to-operate system consists of a filter, volumetric flow meter, piping, dosing valves and barcode reader, among other equipment to guarantee the best performance.",
      // },
      // {
      //   id: "metroval-8",
      //   title: "Fuel Unloading Systems",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "Unloading systems are used to receive fuel and liquid raw materials. Equipped with a system for eliminating gases from unloading the tank, this system ensures that only the volume of liquid is measured, thus guaranteeing the reliability of the process, in addition to measuring density and °INPM and correcting the volume at a temperature of 20° C. Have model approval for custody transfer.",
      // },
      // {
      //   id: "metroval-9",
      //   title: "Brix Measurement System in Must",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "Applied in processes carried out in the sugar and ethanol industry, the equipment promotes the measurement and control of brix in the must, becoming essential for obtaining maximum efficiency in ethanol production. The system stabilizes the °Brix during must formation and offers repetition in the fermentation process, which generates stable supply in the distillery and optimizes production capacity throughout the operation. The system has an automatic cleaning process, in order to avoid crusting and clogging caused by sugar after cooling.",
      // },
      // {
      //   id: "metroval-10",
      //   title: "Production Measurement Systems",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "Developed to effectively control the ethanol produced and assist in the management of plant stocks, the equipment consists of systems for production control, tank inventory and loading control. Considering that the volume of the fluid can vary according to temperatures, the systems developed by Metroval work to measure operational conditions and correct the reference conditions, which are 20°C.",
      // },
      // {
      //   id: "metroval-11",
      //   title: "Fuel Charging Systems",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "Fully automated modular systems for fuel loading bases. They meet metrological requirements of Inmetro and ABNT 5992/2008. They carry out temperature, flow and density measurements, control the flow and report the measured variables, correcting the volume loaded at a temperature of 20ºC.",
      // },
      // {
      //   id: "metroval-12",
      //   title: "Flow Measurement System for Deprimogeneous Elements",
      //   image: "https://via.placeholder.com/400x400",
      //   subtitle: "",
      //   icon: "line-icon-Flick",
      //   btnLink: "/produtos/detalhe",
      //   btnTitle: "See details",
      //   description:
      //     "The oil and gas industry is one of those that uses the product most, due to the principle of flow measurement by differential pressure, especially in the measurement of natural gas, due to its low cost, simplicity of installation and high accuracy, always in compliance with the recognized standards (AGA or ISO) for this type of application. The main and most traditional representative of this category of measuring elements is the orifice plate, which, like the other elements by this principle, generates a differential pressure through the measuring element proportional to the flow rate. The other representatives of this class of meters are Metrocones, integral holes, flow nozzles and Venturi tubes. With the exception of Metrocones, these differential pressure measuring elements require the installation of flow rectifiers to provide an adequate flow profile, thus guaranteeing perfect measurement.",
      // },
      {
        id: "metroval-13",
        title: "Coriolis Mass Meters",
        image: "img/products/metroval/coriolis-flow-meter.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "These customized systems use the principle of volumetric or mass meters using the Coriolis effect. Associated with a pre-determination system and valves, the product offers excellent performance, with a maximum error of 0.5% of the measured value in the range in which it is calibrated. Furthermore, the ease of mobility of the system allows greater agility in the dosing and filling operation and with exact pre-set values. The systems consist of a pump, filter, volumetric or mass flow meter, piping, dosing valves and HMI for Batch Preset, in addition to having a robust structure and easy operation. We develop dosing systems composed of volumetric or mass meters, to be used in dosing liquid raw materials. We also develop mobile or fixed filling systems, which allow the packaging of final products in the most diverse containers, such as cans, drums and containers. This system is widely used for dosing and/or filling paints and varnishes.",
      },
      {
        id: "metroval-14",
        title: "Metering Systems",
        image: "img/products/metroval/metering-systems.png",
        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description:
          "Metroval is a qualified supplier not only at Petrobras, but also at many other operators. Our Measurement Systems enable full control of fiscal, custody transfer, appropriation and operational measurement, fully meeting the technical, legal and metrological requirements of the oil and gas industry.Systems are designed, built and tested in our facilities. Our metrological infrastructure allows you to separately test and validate measuring instruments, such as flow meters, densimeters, tasters, samplers and BSW analyzers. Additionally, our accredited flow laboratory is qualified to carry out initial checks on small and large systems. Metroval was the first company to conclude in 2006 the largest flow measurement contract in national history: the complete adaptation of 14 platforms for oil, gas and water measurement systems for four Petrobras assets in the Campos Basin.With more than 500 systems installed in Brazil and around the world, Metroval's integrated flow measurement systems are supplied in accordance with the technical standards and technical regulations of ANP and Inmetro.",
      }
    ],
  },
  {
    image: "/assets/img/inov8.png",
    title: "Oil in Water Analysers",
    slug: "inov8",
    description:
      "Inov8 Systems was created and launched with a clear vision to research, develop and produce the world's most innovative instrumentation and control systems, to address unresolved challenges.Oil in Water Analyser could be considered the best available technology in this segment. It is manufactured by INOV8, and among his main characteristics we would emphasize:LASER-INDUCED FLUORESCENCE - Accurate and robust monitoring of oil in water concentration.RAPID WAVE ULTRASONIC CLEANING - Optimize cleaning and eliminate damage to the window.LOW MAINTENANCE - No maintenance outside annual service visit. ",
    products: [
      {
        id: "inov8-1",
        title: "Intelligent Control - Oil in Water Probe",
        image: "img/products/inov8/product-1.png",
        subtitle: "EXP Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        New rapid wave ultrasonic self cleaning 
         Choice of standard or high-resolution spectrometry feature 
         Low power DC voltage requirements 
         Multiple Communication options for Management Capabilities 
         Probe with integrated control - zero footprint 
         Adjustable flange mount for probe insertion depth 
         Integral, configurable colour display 
         Less than 25KG weight 
         316SS Exd Hazardous ATEX, IECEx and C1D1 Certification 
        
        Benefits 
         Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
         Simple to use interface for set-up and control
         Lightweight with easy access to instrument without removing from process. 
         Meets hand carry requirements for choppers
         Minimal installation requirements (24VDC, Exd)
         Remote Monitoring and Automatic Process Control
         Continuous real-time measurements; <1 sec response to process changes 
         24 month standard warranty
        `,
      },
      {
        id: "inov8-2",
        title: "Mobile Dosing and Filling Systems",
        image: "img/products/inov8/product-2.png",
        subtitle: "EXS Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `
        Features 
        New rapid wave ultrasonic self cleaning 
        Choice of standard or high-resolution spectrometry feature 
        Low power DC voltage requirements 
        Multiple Communication options for Management Capabilities 
        Small footprint only requiring front access for easy maintenance and installation 
        Large user configurable colour display 
        Less than 25KG weight 
        316SS Exd Hazardous ATEX, IECEx and C1D1 Certification 

        Benefits 
        Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
        Simple to use interface for set-up and control 
        Wall, rack or stand mounting options 
        Meets hand carry requirements for choppers 
        Minimal installation requirements (24VDC, Exd) 
        Remote Monitoring and Automatic Process Control 
        Continuous real-time measurements; <1 sec response time to process changes 
        24 month standard warranty 
        `,
      },
      {
        id: "inov8-3",
        title: "General Purpose Oil in Water - Measurement and Control",
        image: "img/products/inov8/product-3.png",
        subtitle: "GPP Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features
        Unique to the industry - rapid wave ultrasonic self cleaning 
        Low power DC voltage requirements 
        Multiple Communication options for Management Capabilities 
        Probe with integrated Control 
        Adjustable flange mount for probe insertion depth 
        Integrated Colour Display 
        Less than 12KG weight - easy to transport 
        
        Benefits
        • Low cost of ownership with no regular maintenance, no routine calibration
        • Simple to use intuitive interface for set-up and control
        • Lightweight and easy access to instrument without removing from the process 
        • Minimal installation requirements (24VDC)
        • Remote Monitoring and Automatic Process Control
        • Continuous real-time measurements; <1 sec response time to process changes 
        • 24 months standard warranty on parts 
        `,
      },
      {
        id: "inov8-4",
        title:
          "Intelligent Control - Oil in Water Side Stream Customer inspired innovation",
        image: "img/products/inov8/product-4.png",
        subtitle: "GPS Controller",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        New rapid wave ultrasonic self cleaning 
        Choice of standard or high-resolution spectrometry feature 
        Low power DC voltage requirements 
        Multiple Communication options for Management 
        Capabilities 
        Small footprint only requiring front access for easy 
        maintenance and installation 
        Large user configurable colour display 
        Less than 25KG weight 
        
         Benefits 
        Low cost of ownership with no regular maintenance, no routine calibration and no added chemicals 
        Simple to use interface for set-up and control 
        Wall, rack or stand mounting options 
        Minimal installation requirements (24VDC) 
        Remote Monitoring and Automatic Process Control 
        Continuous real-time measurements; <1 sec response time to process changes 
        24 month standard warranty 
        `,
      },
      {
        id: "inov8-5",
        title: "HT405P / HT280P Oil in Water Measurement",
        image: "img/products/inov8/product-5.png",
        subtitle: "HT - Portable Meter",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `Features 
        All-in-one Hand Held Portable & Benchtop Device
        • Built-in rechargeable battery
        • Integrated 10.1” Touchscreen Colour Display
        • Linux based PC operation
        • Simple Intuitive Interface for set—up and control
        • 1.8kg weight
        
        Benefits
        • Flexible, Durable Probe for Measurement
        • Results can be correlated to official lab methods (USEPA 1664B and ISO 9377-1)
        • Full real time Spectrometry for measurement and analysis
        • Continuous real-time measurements with Immediate results
        • 12 months standard warranty
        • Hydrocarbons measured: Crude Oil, Gas Condensate, PAH’s, Phenol’s, lubricants
        `,
      },
    ],
  },
  {
    image: "/assets/img/gsi.png",
    bgColor: "#fff",
    borderColor: "#262b35",
    title: "Gauging Systems",
    slug: "gsi",
    description: `GSI manufactures volume, liquid level, and mass tank gauging equipment and systems for the petroleum, petrochemical, power, food & beverage, pharmaceutical, marine, pulp & paper, and water industries. Providing customers with the means for measurement of volume by Hybrid and Hydrostatic Methods to within Custody Transfer Accuracy (Loss Control) standards in accordance with API, ISO, GOST, and OIML. GSI’s offerings range from turn-key enterprise solutions, to supply of software, instruments, and spare parts. Services are also available, including integration expertise, field service, and bench repair for both GSI’s and competitors’ equipment worldwide.
    In 1995, GSI became the first tank gauging software developer to qualify as a “Microsoft Certified Solution Provider”. Currently, we are a “Microsoft Certified Partner”, developing tank gauging software programs—and other Windows applications—that are expandable from a single user interface to enterprise based solutions, and designed for the .NET Framework.
     In terms of “Inventory Loss Control”, we also can provide the most effective solution for Bulk Liquid Storage Tanks.
    The solution/equipment is manufactured by GSI (Gauging System Inc.), and it includes:
    Tank Gauging (Product Quantity & Quality);
    Overfill & Rupture Protection;
    Leak Detection (Unauthorized Movement);
    Vapour Monitoring (Emissions, PRV, or Blanket Monitoring),
    Tank Bottom or Roof Movement Indication, etc. Redundant and Tridundant solutions.
    `,
    products: [
      {
        id: "gsi-1",
        title:
          "MTG 3018 Redundant - MTG 3012 Multi-function Tank Gauge & MOP 3006 Overfill and Rupture Protection",
        image: "img/gsi.png",

        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The MTG 3018 is the combination of MTG 3012 “Multi-function Tank Gauge and MOP 3006 “Multi-function Overfill Probe”, two completely independent gauges in one physical probe assembly. Each gauge has its own transmitter card and sensor array within the shared gauge housing, thus, only one tank top flange opening is required for mounting “Two” gauges. Each gauge uses independent signal and power sources within the system to maintain complete independence. Exceeds API 2350 Overfill Protection requirements.
        `,
      },
      {
        id: "gsi-2",
        title: "MTG 3014 Multi-function Tank Gauge with Critical Redundancy",
        image: "img/gsi.png",

        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The MTG 3014 is the MTG 3012 with two (2) additional “Critical sensors” or redundant critical sensors.  The two critical sensors are the Vapor Sensor & Bottom Sensor. These two sensors are necessary to maintain a working MTG.  If any of the middle sensors (one or more) fail, the MTG will put them into maintenance mode with a diagnostic registered set, and the MTG will continue to operate.
        In order to minimize the Mean Time to Repair or eliminate total MTG failure on critical application tanks, remote tanks, tanks that are harder to get to or support, we offer the redundant critical sensors. They are added within the MTG 3012 (configured either hot or cold) for redundant Vapor and Bottom sensors.
        NOTE: Other types of sensors for use within MTG configurations are available. Alternative probe materials are also available. Consult GSI with your application and tank needs.
        `,
      },
      {
        id: "gsi-3",
        title: "2570 Mechanical Tank Gauge",
        image: "img/gsi.png",

        subtitle: "",
        icon: "line-icon-Flick",
        btnLink: "/produtos/detalhe",
        btnTitle: "See details",
        description: `The GSI 2570 Mechanical Tank Gauge is a mechanically actuated, float and tape instrument designed to provide continuous level measurement in tanks, vessels, vats, or any other container for liquid storage. Also referred to as Automatic Tank Gauge (ATG), Float and Tape Tank Gauge, Float Type Level Gauge.
        The latest modification (Patent Pending) to the GSI-2570 is the removable checker gland. It is, no longer required to remove the bolts and back cover, cassette, TSSA, and tape to change the checker assembly, checker or hair spring. This new method will save hours of maintenance.
        Float & Tape Tank Gauge Applications

        Petroleum Tanks
        Airport Tanks
        Chemical Tanks
        Waste Water Treatment Tanks
        Lubricant Oil Tanks

        SPECIFICATIONS

        Mounting:
        Pipe & Bracket Mounting System
        Pressure:
        15 - 25 psi
        Temperature Range:
        -40 °F to 185 °F (-40 °C to 85 °C)
        Weight (head only):
        28 lbs. (12.7 kg)
        `,
      },
    ],
  },
  {
    image: "/assets/img/valtek.png",
    bgColor: "#fff",
    title: "Valtek",
    slug: "valtek",
    description: `ValtekSul is a privately owned company, established in Brazil as a leading Control Valves Manufacturer. During the last 40 years ValtekSul has successfully supplied control valves to the local and international On-shore and Off-shore markets.
    ValtekSul is engineering & manufacturing control valves in Brazil under strict international standards. Our industrial infrastructure has an installed capacity to produce around 2,000 control valves per year, as well as to execute off-shore field services to fully support our considerable installed base.
    `,
    products: [
      {
        "id": "gxl-1",
        "title": "GXL",
        "category": "General Service",
        "image": "img\/products\/valtek\/gxl-1.png",
        "description": [
          "High performance",
          "Compact",
          "Low cost",
          "Integrate control valve package"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1\/2 to 4 in. (DN 15 a 100)"
          },
          {
            "title": "Rating",
            "value": "150-300 (PN 10-40)"
          },
          {
            "title": "Connections",
            "value": "Flanged: 150-300"
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel WCC",
              "Stainless Steel CF3M",
              "Stainless Steel CF8M"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA-XL",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "General Service",
        "btnTitle": "See details"
      },
      {
        "id": "gls-2",
        "title": "GLS",
        "category": "General Service",
        "image": "img\/products\/valtek\/gls-2.png",
        "description": [
          "Superior performance",
          "Robust",
          "Compact",
          "Broad solutions",
          "Envelope with high flexibility"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1\/2 to 36 in. (DN 15 a 900)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10-40)"
          },
          {
            "title": "Connections",
            "value": [
              "Flanged: 150-300-600",
              "Welded: BW or SW",
              "Screwed: 1\/2 to 2\u2019 (DN 15-50)"
            ]
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA; LA-S e LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "General Service",
        "btnTitle": "See details"
      },
      {
        "id": "glh-3",
        "title": "GLH",
        "category": "High Pressure",
        "image": "img\/products\/valtek\/glh-3.png",
        "description": [
          "Superior performance",
          "Robust",
          "Compact",
          "Broad solutions",
          "Envelope with high flexibility"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 30 in. (DN 25 to 750)"
          },
          {
            "title": "Rating",
            "value": "900-1500-2500 (PN 62 to 170)"
          },
          {
            "title": "Connections",
            "value": [
              "Flanged: 900-1500-2500",
              "Welded: BW or SW"
            ]
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Cromo-moly",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA; LA-S and LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "High Pressure",
        "btnTitle": "See details"
      },
      {
        "id": "gls-&-glh-4",
        "title": "GLS & GLH",
        "category": "Large Sizes",
        "image": "img\/products\/valtek\/gls-&-glh-4.png",
        "description": [
          "Superior performance",
          "Compact",
          "Lightweight body",
          "Broad solutions",
          "Envelope with high flexibility"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": [
              "GLS: 14 to 36 in. (DN 350 to 900)",
              "GLH: 14 to 30 in. (DN 350 to 750)"
            ]
          },
          {
            "title": "Rating",
            "value": "150 to 2500 (PN 10 to 170)"
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Cromo-moly",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA; LA-S and LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Large Sizes",
        "btnTitle": "See details"
      },
      {
        "id": "glx-5",
        "title": "GLX",
        "category": "Expanded Outlet",
        "image": "img\/products\/valtek\/glx-5.png",
        "description": [
          "High performance",
          "Especially designed for an economical assembly when the valve trim is much smaller than the piping diameter"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "4 to 12 in. (DN 100 to 300)"
          },
          {
            "title": "Rating",
            "value": "150 to 1500 (PN 10 to 100)"
          },
          {
            "title": "Connections",
            "value": "Flanged: 150 to 1500"
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Cromo-moly",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA; LA-S and LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Expanded Outlet",
        "btnTitle": "See details"
      },
      {
        "id": "glb-6",
        "title": "GLB",
        "category": "Bar Stock",
        "image": "img\/products\/valtek\/glb-6.png",
        "description": [
          "Extremely resistant to wear",
          "Customized body",
          "Plug and Seat (according to applications need)"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 6 pol. (DN 25 to 150)"
          },
          {
            "title": "Rating",
            "value": "150 to 4500 (PN 10 to 310)"
          },
          {
            "title": "Connections",
            "value": [
              "Integral flanges",
              "Welded: BW or SW"
            ]
          },
          {
            "title": "Body Materials",
            "value": "Other Casting Alloys"
          },
          {
            "title": "Actuador",
            "value": [
              "Series LA; LA-S and LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Bar Stock",
        "btnTitle": "See details"
      },
      {
        "id": "glc-7",
        "title": "GLC",
        "category": "Cryogenic Cold Box",
        "image": "img\/products\/valtek\/glc-7.png",
        "description": [
          "Especially designed for cryogenic applications",
          "Light body and Top-Entry\u2019 assembly",
          "Sealing up to Class VI",
          "Up to -360\u00baF (-213\u00baC)"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 12 in. (DN 25 to 300)"
          },
          {
            "title": "Rating",
            "value": "150 to 1500 (PN 10 to 100)"
          },
          {
            "title": "Connections",
            "value": [
              "Integral Flanges",
              "Welded connection"
            ]
          },
          {
            "title": "Body Materials",
            "value": [
              "Stainless Steel",
              "Cryogenic Use Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Serie LA; LA-S",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Cryogenic Cold Box",
        "btnTitle": "See details"
      },
      {
        "id": "gly-8",
        "title": "GLY",
        "category": "Y Type Angle",
        "image": "img\/products\/valtek\/gly-8.png",
        "description": [
          "Large flow capacity per diameter",
          "More linear flow",
          "Flow passage is less obstructed decreasing turbulence in valve and piping"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1\/2 to 24 in. (DN 15 to 600)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10 to 40)"
          },
          {
            "title": "Connections",
            "value": "Integral Flanges"
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Serie LA; LA-S; LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Y Type Angle",
        "btnTitle": "See details"
      },
      {
        "id": "gle-9",
        "title": "GLE",
        "category": "Erosive Applications",
        "image": "img\/products\/valtek\/gle-9.png",
        "description": [
          "Especially designed for severe services, flashing and erosives",
          "Especially designed for slurry fluid",
          "Bayonet type model"
        ],
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 18 in. (DN 25 to 450)"
          },
          {
            "title": "Rating",
            "value": "150-1500 (PN 10 to 100)"
          },
          {
            "title": "Connections",
            "value": "Integral Flanges"
          },
          {
            "title": "Body Materials",
            "value": [
              "Carbon Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Serie LA; LA-S; LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Erosive Applications",
        "btnTitle": "See details"
      },
      {
        "id": "gls-10",
        "title": "GLS",
        "category": "Bellows-pac\u00ae",
        "image": "img\/products\/valtek\/gls-10.png",
        "description": "The Bellows-Pac metal seal uses a formed bellows design with mini welded joints. External pressurizartion of the bellows incresases cycle life and the maximum allowable operating pressure full-cycle life of up 5 million cycles.",
        "specifications": [
          {
            "title": "Size",
            "value": "1\/2 to 8 in. (DN 15 a 200)"
          },
          {
            "title": "Rating",
            "value": "150-300 (PN 10 to 20)"
          },
          {
            "title": "Body forms",
            "value": "Globe, Angle, Expanded Outlet"
          },
          {
            "title": "Bellows materials",
            "value": [
              "Inconel\u00ae, Hastelloy\u00ae C-22",
              "Other materials"
            ]
          },
          {
            "title": "Packing",
            "value": "Double set"
          },
          {
            "title": "Actuador",
            "value": [
              "Serie LA; LA-S; LA-EF",
              "High thrust piston cylinder"
            ]
          }
        ],
        "subtitle": "Bellows-pac\u00ae",
        "btnTitle": "See details"
      },
      {
        "id": "beta-1",
        "title": "BETA",
        "category": "Reduces control valve noise",
        "image": "img\/products\/valtek\/beta-1.png",
        "description": "Beta Valve Trim effectively reduces control valve noise in a range of gas applications.\r\n                    The heavy-duty, drilled hole cage one\r\n                    of the common and effective solutions to control valve noise.",
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 36 in. (DN 25 to 900)"
          },
          {
            "title": "Rating",
            "value": "150-2500 (PN 10-170)"
          },
          {
            "title": "Materials Atenuator",
            "value": "316 SS (UNS S 31600) Inconel\u00ae"
          },
          {
            "title": "Plug",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS\/Alloy #6 (UNS S 30006)",
              "400 SS series",
              "Inconel\u00ae \/ Other Alloys"
            ]
          },
          {
            "title": "Seat",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS\/Alloy #6 (UNS S 30006)",
              "400 SS series",
              "Inconel\u00ae \/ Other Alloys"
            ]
          }
        ],
        "subtitle": "Reduces control valve noise",
        "btnTitle": "See details"
      },
      {
        "id": "gamma-2",
        "title": "GAMMA",
        "category": "Cavitation in liquids",
        "image": "img\/products\/valtek\/gamma-2.png",
        "description": "Gamma Trim prevents cavitation from forming and minimizes hydro-dynamic\r\n                            noise even under the most severe liquid applications. In the cartridge Gamma each stage is designed with a series of expansion holes and intersecting circunferential channels that restrict the flow.",
        "specifications": [
          {
            "title": "Size",
            "value": "2 to 36 in. (DN 50 to 900)"
          },
          {
            "title": "Rating",
            "value": "150-2500 (PN 10-170)"
          },
          {
            "title": "Materials Atenuator",
            "value": [
              "316 SS (UNS S 31600)",
              "416 SS (UNS S 41600)",
              "Inconel\u00ae",
              "Other Alloys"
            ]
          },
          {
            "title": "Cartridge Low Flow",
            "value": "416SS (UNS S 41600)"
          },
          {
            "title": "Shutter & Sede",
            "value": [
              "316 SS\/Alloy #6 (UNS S 30006)",
              "416 SS (UNS S 41600)",
              "420 SS (UNS S 42000)",
              "Other Alloys"
            ]
          }
        ],
        "subtitle": "Cavitation in liquids",
        "btnTitle": "See details"
      },
      {
        "id": "delta-3",
        "title": "DELTA",
        "category": "Minimizes noise and eliminates the damage effects of cavitation",
        "image": "img\/products\/valtek\/delta-3.png",
        "description": "The Delta Trim ability to minimize noise and eliminate the damaging effects of cavitation makes in the ValtekSul control valves an effective choice for gas and liquid processes with high pressure drop conditions. Delta trim has confirmed its ability to reduce noise and eliminate cavitation.",
        "specifications": [
          {
            "title": "Size",
            "value": "1,5 to 36 in. (DN 40 to 900)"
          },
          {
            "title": "Rating",
            "value": "150-2500 (PN 10 to 170)"
          },
          {
            "title": "Materials Atenuator",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS\/Alloy #6 (UNS S 30006)",
              "416 SS (UNS S 41600)",
              "Inconel\u00ae and Other Alloys"
            ]
          },
          {
            "title": "Shutter",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS\/Alloy #6 (UNS S 30006)",
              "Other Alloys"
            ]
          },
          {
            "title": "Seat",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS\/Alloy #6 (UNS S 30006)",
              "Other Alloys"
            ]
          }
        ],
        "subtitle": "Minimizes noise and eliminates the damage effects of cavitation",
        "btnTitle": "See details"
      },
      {
        "id": "alpha-4",
        "title": "ALPHA",
        "category": "Is a cost-effective trim that minimizes cavitation damage",
        "image": "img\/products\/valtek\/alpha-4.png",
        "description": "The Alpha Trim utilizes a number of small diametrically opposed flow holes through the walls of a special seat retainer.The flow capacity and characteristics are determined by size and spacing of the holes in the seat retainer.",
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 20 in. (DN 25 to 500)"
          },
          {
            "title": "Rating",
            "value": "150-2500 (PN 10-170)"
          },
          {
            "title": "Materials Seat Retainer",
            "value": [
              "316 SS (UNS S 31600)",
              "316 SS (Hardened)",
              "400 SS Series (Hardened)"
            ]
          },
          {
            "title": "Plug",
            "value": [
              "316 SS\/Alloy #6 (UNS S 30006)",
              "400 SS Series (Hardened)"
            ]
          },
          {
            "title": "Seat",
            "value": [
              "316 SS\/Alloy #6 (UNS S 30006)",
              "400 SS Series (Hardened)"
            ]
          }
        ],
        "subtitle": "Is a cost-effective trim that minimizes cavitation damage",
        "btnTitle": "See details"
      },
      {
        "id": "omega-5",
        "title": "OMEGA",
        "category": "Trim to control cavitation in low flows",
        "image": "img\/products\/valtek\/omega-5.png",
        "description": "The Omega Trim contains the cavitation bubbles in the center of the plug-retainer. Impiging jets create a column of cavitation in the center of the plug-retainer. The characterization available to cover a wide range of applications.",
        "specifications": [
          {
            "title": "Size",
            "value": "1\/2 - 2 in. (DN 15-50)"
          },
          {
            "title": "Rating",
            "value": [
              "150 to 2500",
              "(PN 10 to 170)"
            ]
          },
          {
            "title": "Materials Plug",
            "value": [
              "420 SS Hardened (UNS S 42000)",
              "Other Alloys"
            ]
          },
          {
            "title": "Seat",
            "value": [
              "420 SS Hardened (UNS S 42000)",
              "Solid Alloy #6 (UNS R 30006)",
              "Other Alloys"
            ]
          },
          {
            "title": "CV Values",
            "value": "1,00 a 2,50"
          }
        ],
        "subtitle": "Trim to control cavitation in low flows",
        "btnTitle": "See details"
      },
      {
        "id": "omicron-6",
        "title": "OMICRON",
        "category": "Microplug to control cavitation",
        "image": "img\/products\/valtek\/omicron-6.png",
        "description": "Omicron Trim is used to effectively handle cavitation in ValtekSul globe control valves that require small flow rates. The plug head is designed with a series of special engineered grooves that continually expand as they progress diagonally along the lenght of the plug head.",
        "specifications": [
          {
            "title": "Size",
            "value": "1-1, 5-2 in. (DN 25-40-50)"
          },
          {
            "title": "Rating",
            "value": "150 to 2500 (PN 10 to 170)"
          },
          {
            "title": "Materials Plug",
            "value": [
              "400 SS Series (Hardened)",
              "Solid Alloy #6 (UNS R 30006)"
            ]
          },
          {
            "title": "Seat Ring",
            "value": [
              "400 SS Series (Hardened)",
              "Solid Alloy #6 (UNS R 30006"
            ]
          },
          {
            "title": "CV Values",
            "value": "0,007 to 1,25"
          }
        ],
        "subtitle": "Microplug to control cavitation",
        "btnTitle": "See details"
      },
      {
        "id": "bxl-1",
        "title": "BXL",
        "category": [
          "High Performance Double Offset",
          "Butterfly Valve Moderate Pressures"
        ],
        "image": "img\/products\/valtek\/bxl-1.png",
        "description": "The Series Bxl is a heavy-duty manual and automatic throttling butterfly control valve with a bieccentric disk to moderate pressure applications",
        "specifications": [
          {
            "title": "Size",
            "value": "2 to 12 in. (DN 50 to 300)"
          },
          {
            "title": "Rating",
            "value": "150-300 (PN 10-20)"
          },
          {
            "title": "Connections",
            "value": [
              "Wafer",
              "Lug",
              "Double flange"
            ]
          },
          {
            "title": "Body materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Otther Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "RA Series",
              "High thrust rotary piston cylinder",
              "RP Series",
              "Rack\/pinion"
            ]
          }
        ],
        "subtitle": [
          "High Performance Double Offset",
          "Butterfly Valve Moderate Pressures"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "bxl-2",
        "title": "BXL",
        "category": "Cryogenic",
        "image": "img\/products\/valtek\/bxl-2.png",
        "description": "High performance double offset heavy-duty butterfly manual or automatic throttling valve to cryogenic applications",
        "specifications": [
          {
            "title": "Size",
            "value": "2 to 24 pol. (DN 50 to 600)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10-40)"
          },
          {
            "title": "Temperature",
            "value": "-425\u00b0F minimum (-254\u00b0C)"
          },
          {
            "title": "Connections",
            "value": [
              "Wafer",
              "Lug",
              "Double flange"
            ]
          },
          {
            "title": "Body materials",
            "value": [
              "Stainless Steel (CF8M)",
              "Other cryogenic",
              "casting materials"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "RA Series",
              "High thrust rotary piston cylinder",
              "RP Series",
              "Rack\/pinion"
            ]
          }
        ],
        "subtitle": "Cryogenic",
        "btnTitle": "See details"
      },
      {
        "id": "bxl-3",
        "title": "BXL",
        "category": [
          "High Performance Double Offset",
          "Butterfly Valve - Large Sizes"
        ],
        "image": "img\/products\/valtek\/bxl-3.png",
        "description": "The Series Bxl is a heavy-duty manual and automatic throttling butterfly control valve with a bieccentric disk for large size applications",
        "specifications": [
          {
            "title": "Size",
            "value": "14 to 36 in. (DN 350 to 900)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10-40)"
          },
          {
            "title": "Connections",
            "value": [
              "Wafer",
              "Lug",
              "Double flange"
            ]
          },
          {
            "title": "Body materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "Logus Series",
              "Scotch yoke cylinder"
            ]
          }
        ],
        "subtitle": [
          "High Performance Double Offset",
          "Butterfly Valve - Large Sizes"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "vxl-4",
        "title": "VXL",
        "category": [
          "Segmented Ball",
          "Control Valve Sizes"
        ],
        "image": "img\/products\/valtek\/vxl-4.png",
        "description": "The VXL is a segmented, ball control valve, that provides excepcional control and rangeability for process industries",
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 24 in. (DN 25 to 600)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10-40)"
          },
          {
            "title": "Connections",
            "value": [
              "Wafer",
              "Double flange"
            ]
          },
          {
            "title": "Distance between flanges",
            "value": "ANSI\/ISA 75.08.02"
          },
          {
            "title": "Body materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "RA Series",
              "High thrust rotary piston cylinder"
            ]
          }
        ],
        "subtitle": [
          "Segmented Ball",
          "Control Valve Sizes"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "exl-5",
        "title": "EXL",
        "category": [
          "Eccentric Rotary Plug",
          "Short pattern"
        ],
        "image": "img\/products\/valtek\/exl-5.png",
        "description": "The EXL eccentric rotary plug control valve is for applications demanding higher rangeability, precise control and higher flow capacity",
        "specifications": [
          {
            "title": "Size",
            "value": "2 to 12 in. (DN 50 to 300)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN-20)"
          },
          {
            "title": "Connections",
            "value": [
              "Wafer",
              "Double flange"
            ]
          },
          {
            "title": "Distance between flanges",
            "value": "ANSI\/ISA 75.08.01 RF"
          },
          {
            "title": "Body materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "RA Series",
              "High thrust rotary piston cylinder"
            ]
          }
        ],
        "subtitle": [
          "Eccentric Rotary Plug",
          "Short pattern"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "exl-6",
        "title": "EXL",
        "category": [
          "Eccentric Rotary Plug",
          "Long pattern"
        ],
        "image": "img\/products\/valtek\/exl-6.png",
        "description": "The rotary plug valve EXL has an advanced technological concept that combines the strenght of globe type valve and the operational efficiency of a rotary valve. Distance between flanges is identical to globe valves",
        "specifications": [
          {
            "title": "Size",
            "value": "1 to 24 in. (DN 25 to 600)"
          },
          {
            "title": "Rating",
            "value": "150-300-600 (PN 10-40)"
          },
          {
            "title": "Connections",
            "value": "Double flange"
          },
          {
            "title": "Distance between flanges",
            "value": "ANSI\/ISA 75.08.02"
          },
          {
            "title": "Body materials",
            "value": [
              "Carbon Steel",
              "Stainless Steel",
              "Other Casting Alloys"
            ]
          },
          {
            "title": "Actuador",
            "value": [
              "RA Series",
              "High thrust rotary piston cylinder"
            ]
          }
        ],
        "subtitle": [
          "Eccentric Rotary Plug",
          "Long pattern"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "la-series-1",
        "title": "LA SERIES",
        "category": [
          "Linear Actuator",
          "Spring Cylinder"
        ],
        "image": "img\/products\/valtek\/la-series-1.png",
        "description": "The ValtekSul spring cylinder actuator is a powerful, high-performance pneumatic unit that provides positive throttling operation for automatic globe control valves. The LA Series actuator is fully field reversible for air-to-open or air-to-close action without additional parts; a spring provides fail-safe operation",
        "specifications": [
          {
            "title": "Size",
            "value": [
              "LA Series: 15; 25; 50; 100; 200; 300; 400; 600",
              "LA-S Series: 25; 50; 100; 150; 200; 300; 400; 500",
              "LA-UC Series: 25; 50; 100; 200",
              "LA-EF Series: 100; 200; 300; 400"
            ]
          },
          {
            "title": "Materials Cylinder",
            "value": [
              "Anodizado Aluminum",
              "Carbon Steel painted",
              "Yoke: Phosphate, painted ductile Iron"
            ]
          },
          {
            "title": "Auxiliar Equipment",
            "value": [
              "Handwheels",
              "Air Filters",
              "Flow Booster",
              "Positioners"
            ]
          }
        ],
        "subtitle": [
          "Linear Actuator",
          "Spring Cylinder"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "ra-series-2",
        "title": "RA SERIES",
        "category": [
          "Linear Actuator",
          "Spring Cylinder"
        ],
        "image": "img\/products\/valtek\/ra-series-2.png",
        "description": "The ValtekSul RA Series spring cylinder rotary actuator is a high pressure, ruggedand compact actuator with high torque and pneumatic stiffness for excellent throttling capabilities. The actuator uses a rocking piston for direct conversion of linear motion to rotary motion",
        "specifications": [
          {
            "title": "Size",
            "value": "LA Series: 25; 50; 100; 200"
          },
          {
            "title": "Materials Cylinder",
            "value": [
              "Anodizado Aluminum",
              "Epoxi painted",
              "Yoke: Phosphate, painted ductile Iron",
              "Splined Lever Arm: Ductile Iron"
            ]
          }
        ],
        "subtitle": [
          "Linear Actuator",
          "Spring Cylinder"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "logus-3",
        "title": "LOGUS",
        "category": [
          "Rotary Actuator",
          "Extra-reinforced Scotch-Yoke Type"
        ],
        "image": "img\/products\/valtek\/logus-3.png",
        "description": "The Logus pneumatic scotch yoke ValtekSul actuators are the cost-effective choice for on-off and inching applications of quarter turn medium-to-large size valves in general process industries.",
        "specifications": [
          {
            "title": "Size",
            "value": "50; 100; 200"
          },
          {
            "title": "Materials Cylinder",
            "value": [
              "Carbon Steel",
              "Interior surface is honed and hard chrome plate",
              "Scotch-Yoke: Ductile Iron",
              "Rod Bearing: PTFE lined metal, Reinforced bronze bearing",
              "Piston Seals: High strenght Alloy spring steel",
              "Spring module: High strenght Alloy spring steel, High cycle life performance"
            ]
          }
        ],
        "subtitle": [
          "Rotary Actuator",
          "Extra-reinforced Scotch-Yoke Type"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "chronos-idp7600-1",
        "title": "CHRONOS IDP7600",
        "category": [
          "Digital Valve Positioner",
          "Advanced Performance"
        ],
        "image": "img\/products\/valtek\/chronos-idp7600-1.png",
        "description": "The advanced performance Chronos IDP7600 digital valve positioner models offer superior control technology for pneumatically actuated valves",
        "specifications": [
          {
            "title": "Communication\/Control",
            "value": "4-20m with HART\u00ae Version 7"
          },
          {
            "title": "Pneumatics",
            "value": "Double or single acting"
          },
          {
            "title": "Operation temperatures",
            "value": "-4 a +185\u00b0F"
          },
          {
            "title": "Materials",
            "value": [
              "Aluminum (painted polyurethane epoxy)",
              "Stainless Steel housing",
              "Stainless Steel pneumatic",
              "components Series 300"
            ]
          },
          {
            "title": "I\/O",
            "value": "4-20mA output"
          },
          {
            "title": "Mounting",
            "value": "Rotary or linear pneumatic actuator"
          }
        ],
        "subtitle": [
          "Digital Valve Positioner",
          "Advanced Performance"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "hp-2000-2",
        "title": "HP 2000",
        "category": [
          "Analogic Positioner",
          "Advanced Performance"
        ],
        "image": "img\/products\/valtek\/hp-2000-2.png",
        "description": "The HP 2000 is a premium performance, two-stage positioner with fast, sensivity, compact and field reversible and ruggedly built for dynamic response",
        "specifications": [
          {
            "title": "Input signal range",
            "value": [
              "Pneumatic Module: 3-15 psi, 2 or 3-way split range",
              "I\/P Module: 4-20mA, 2 ou 3-way split rang"
            ]
          },
          {
            "title": "Supply pressure",
            "value": "30 to 150 psi (2 to 10.3 bar)"
          },
          {
            "title": "Temperature range",
            "value": "-20 to +185\u00b0F"
          },
          {
            "title": "Materials",
            "value": [
              "Aluminum epoxy powder painted",
              "Stainless Steel Series 300",
              "Pneumatic components"
            ]
          }
        ],
        "subtitle": [
          "Analogic Positioner",
          "Advanced Performance"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "fb-100-3",
        "title": "FB 100",
        "category": "Flow Booster",
        "image": "img\/products\/valtek\/fb-100-3.png",
        "description": "The ValtekSul Booster FB 100 is a one-to-one pressure regulator that amplifies flow to increase the stroking speed of actuators",
        "specifications": [
          {
            "title": "Input\/output ratio",
            "value": "One to one"
          },
          {
            "title": "Maximum Supply",
            "value": "10.3 bar (150 psi)"
          },
          {
            "title": "Temperature range",
            "value": "-40 to 165\u00b0F"
          },
          {
            "title": "Connections",
            "value": [
              "Signal: 1\/4 NPTF",
              "Supply: 3\/4 NPTF",
              "Output: 3\/4 NPTF"
            ]
          },
          {
            "title": "Maximum Cv",
            "value": [
              "Supply: 5.0",
              "Exhaust: 3.0"
            ]
          }
        ],
        "subtitle": "Flow Booster",
        "btnTitle": "See details"
      },
      {
        "id": "ds-variflow-1",
        "title": "DS VARIFLOW",
        "category": [
          "Variable Area",
          "Desuperheater"
        ],
        "image": "img\/products\/valtek\/ds-variflow-1.png",
        "description": "The ValtekSul VariFlow Series multiple nozzles desuperheaters are designed to combine excellent water atomization with high rangeability. The VariFlow offers a large variety of nozzle combinations (number and sizes) in order to provide accurate control of steam temperature in whatever condition",
        "specifications": [
          {
            "title": "Size",
            "value": [
              "Water: 1; 1\u00bd; 2; 3 in.",
              "Steam: 3 and 4 in."
            ]
          },
          {
            "title": "Rating",
            "value": "ASME\/ANSI 150 to 2500"
          },
          {
            "title": "Connections",
            "value": "RF; RTJ; BW"
          },
          {
            "title": "Cv Range",
            "value": "Up to 50:1"
          },
          {
            "title": "Rangeability",
            "value": "Up to 50:1"
          }
        ],
        "subtitle": [
          "Variable Area",
          "Desuperheater"
        ],
        "btnTitle": "See details"
      },
      {
        "id": "dm-varibull-2",
        "title": "DM VARIBULL",
        "category": "Desuperheater",
        "image": "img\/products\/valtek\/dm-varibull-2.png",
        "description": "The multiple design VariBull is equipped with several DM wall-mounted nozzles connected among themselves and welded to an injection chamber BW inserted into the steam pipe. The nozzles of MDM design may have different size and settings, for top performances of desuperheating at various flowrates, and to improve turndown as well",
        "specifications": [
          {
            "title": "Size",
            "value": [
              "Water Connetion: Singles DM: 1\/2 to 2 in.; Type MDM: 1 to 4 in.",
              "Chamber or Pipe: From 8 to 40 in."
            ]
          },
          {
            "title": "Rating",
            "value": [
              "ASME\/ANSI 150 a 2500",
              "Greater size on request"
            ]
          },
          {
            "title": "Rating",
            "value": [
              "Water: ANSI 150-1500",
              "Steam: ANSI 150-1500"
            ]
          },
          {
            "title": "Materials",
            "value": [
              "Desuperheaters: Carbon or Cr-Mo Steel",
              "Injection Chamber: Same material as the pipe"
            ]
          }
        ],
        "subtitle": "Desuperheater",
        "btnTitle": "See details"
      },
      {
        "id": "dy-varispring-3",
        "title": "DY VARISPRING",
        "category": [
          "Variable Area",
          "Desuperheater"
        ],
        "image": "img\/products\/valtek\/dy-varispring-3.png",
        "description": "The variable area spring assisted desuperheater with DM nozzles minimizes the limitation of fixed area types deriving from the fluid velocity reduction when flowrate decreases. This is obtainedby automatically reducing the passage area when flowrate decreases to maintain a fluid velocity consistent with the need of a good atomization",
        "specifications": [
          {
            "title": "Size",
            "value": [
              "Water Connetion: Water Connetion: 1 to 3 in.",
              "Steam pipe: 4 to 20 in."
            ]
          },
          {
            "title": "Rating",
            "value": "ASME\/ANSI 150 to 1500"
          },
          {
            "title": "Materials",
            "value": [
              "Nozzle Body: AISI 422",
              "Plug: 17-4PH H1100",
              "Spring: Inconel X750"
            ]
          }
        ],
        "subtitle": [
          "Variable Area",
          "Desuperheater"
        ],
        "btnTitle": "See details"
      }
    ],
  },
];
